// Core variables and mixins
@import "res/scss/__variables.scss";
@import "res/scss/__mixins.scss";

.main-visual {
  height: 600px;
  background-image:url(/views/res/imgs/main/img_main_visual_m.jpg);
  background-repeat:no-repeat;
  background-position:top left;
  background-size: cover;
  overflow: hidden;
  @include media-query-min(md){
    background-image:url(/views/res/imgs/main/img_main_visual.jpg);
    background-position:center;
  }
  @include media-query-min(lg){
    height:100vh;
    background: none;
  }
  .main-bg{
    @include media-query-min(lg){
      position: fixed;
      width: 100%;
      height: 100%;
      background: url(/views/res/imgs/main/img_main_visual.jpg) no-repeat center / cover;
      overflow: hidden;
    }
  }
  .main-visual-text {
    position:absolute;
    top: 45%;
    transform: translateY(-50%);
    @include media-query-min(sm){
      top: 50%;
    }
    @include media-query-max(sm){
      padding: 0 20px;
      left: 0;
    }
  }
  .visual-subtitle {
    line-height:1.6;
    margin-bottom:30px;
    font-size:16px;
    font-weight: 700;
    letter-spacing: -0.02em;
    color:#fff;
    @include media-query-min(lg){
      margin-bottom:33px;
      font-size:20px;
      font-weight: 400;
    }
  }
  .visual-title {
    line-height: 1.228;
    margin-bottom:11px;
    font-size:35px;
    font-weight: 700;
    letter-spacing: -0.02em;
    color:#fff;
    @include media-query-min(lg){
      margin-bottom:7px;
      line-height: 1.3;
      font-size:50px;
      text-shadow: 0 0 5px rgba(0,0,0,.4);
    }
  }
}